import axios from "axios";
import routers from "./routers";
import store from "@/store";
import Config from "@/settings";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "@/utils/auth"; // getToken from cookie
import { buildMenus } from "@/api/system/menu";
import { filterAsyncRouter } from "@/store/modules/permission";
import { deliveryRemindersFn } from "@/api/supplyChainsManage/basicInformation.js";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ["/login"]; // no redirect whitelist

/**获取服务器公共图片域名 */
if (store.state.public.publicImgUrl == "") {
  store.dispatch("SetPublicImgUrl");
}

const router = routers;

router.beforeEach((to, from, next) => {
  if (to.query.title) {
    to.meta.title = to.query.title;
  }
  if (to.meta.title) {
    document.title = to.meta.title + " - " + Config.title;
  }
  if (to.query.title) {
    to.meta.title = to.query.title;
  }
  NProgress.start();
  if (getToken()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === "/login") {
      sessionStorage.setItem("projectCode", to.query.projectCode);
      next({ path: "/" });
      NProgress.done();
    } else {
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store
          .dispatch("GetInfo")
          .then((res) => {
            // 拉取user_info
            // 动态路由，拉取菜单
            if (res.roles.includes("home:panelGroup")) {
              loadMenus(next, { path: "/panelGroup/index" });
              // loadMenus(next, to);
            } else {
              loadMenus(next, { path: "/dashboard" });
              // loadMenus(next, to);
            }
          })
          .catch((err) => {
            // console.log(err)
            store.dispatch("LogOut").then(() => {
              window.location.href = process.env.VUE_APP_RE_LOGIN; // 为了重新实例化vue-router对象 避免bug
            });
          });
        // 登录时未拉取 菜单，在此处拉取
      } else if (store.getters.loadMenus) {
        // 修改成false，防止死循环
        store.dispatch("updateLoadMenus").then((res) => {});

        loadMenus(next, to);
      } else {
        axios.defaults.headers.common["MenuId"] = to.meta.id;
        next();
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next(`/login?redirect=${to.path}`); // 否则全部重定向到登录页
      NProgress.done();
    }
  }
});

export const loadMenus = (next, to) => {
  buildMenus().then((res) => {
    // console.log('菜单路由',res) zejun
    const asyncRouter = filterAsyncRouter(res);
    if (!window.__POWERED_BY_QIANKUN__) {
      asyncRouter.push({ path: "*", redirect: "/404", hidden: true });
    }
    store.dispatch("GenerateRoutes", asyncRouter).then(() => {
      // 存储路由
      router.addRoutes(asyncRouter); // 动态添加可访问路由表
      next({ ...to, replace: true });
    });
  });
};

router.afterEach((e) => {
  //动态更改新建 或者编辑字样
  if (e.query.isKey == "edit" && e.meta.title.indexOf("编辑") == -1) {
    e.meta.title = "编辑" + e.meta.title;
  } else if (e.query.isKey == "add" && e.meta.title.indexOf("新建") == -1) {
    e.meta.title = "新建" + e.meta.title;
  }
  if (e.path.indexOf("supplyChainsManage") > -1) {
    if (process.env.NODE_ENV === "development") {
      return;
    }
    deliveryRemindersFn().then((res) => {
      if (res.code != 200) {
        store.state.supplyChainsManage.msgTipsShow = true;
        store.state.supplyChainsManage.msgContent = res.message;
        return;
      } else {
        store.state.supplyChainsManage.msgTipsShow = false;
      }
    });
  }
  NProgress.done(); // finish progress bar
});
