const state = {
  agreement: undefined,
  template: undefined,
};

const mutations = {
  //   SET_AGREEMENT_CONTENT(state, data) {
  //     state.contractContent = data;
  //   },
  //   SET_TEMPLATE_CONTENT(state, data) {
  //     state.templateContent = data;
  //   },
};

export default {
  namespaced: true,
  state,
};
